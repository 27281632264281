<template>
    <el-dialog
        custom-class="custom-dialog"
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="1400px"
        top="0"
        @close="closeDialog">
        <div class="scrollElement">
            <div class="header">
                <div class="header-item">
                    <span>电梯名称:</span>
                    <el-input
                        placeholder="请输入内容"
                        v-model="name"
                        clearable>
                    </el-input>
                </div>
                <div class="header-item">
                    <span>电梯注册代码:</span>
                    <el-input
                        placeholder="请输入内容"
                        v-model="code"
                        clearable>
                    </el-input>
                </div>
                <div class="header-item">
                    <span>计划类型:</span>
                    <el-select v-loading="statusLoading" v-model="maintainType" clearable filterable placeholder="请选择">
                        <el-option
                        v-for="item in statusList"
                        :key="item.dictKey"
                        :label="item.dictValue"
                        :value="item.dictKey">
                        </el-option>
                    </el-select>
                </div>

                <el-button type="primary" @click="getTable">查询</el-button>
                <el-button type="primary" @click="reset">重置</el-button>
            </div>

            <div>
                <el-table
                v-loading="loading"
                :data="tableData"
                stripe
                ref="multipleTable"
                height="500px"
                style="width: 100%">
                    <el-table-column type="index" label="序号" width="50px" />
                    <el-table-column align='center' prop="eleCode" label="电梯注册代码" width="150px"/>
                    <el-table-column align="center" prop="eleName" label="电梯名称" min-width="200px" />
                    <el-table-column align='center' prop="startTime" label="计划时间" width="150px"/>
                    <el-table-column align='center' prop="signTime" label="签到时间" width="150px"/>
                    <el-table-column align='center' prop="finishTime" label="完成时间" width="150px"/>
                    <el-table-column align='center' prop="maintainType" label="计划类型" >
                        <template slot-scope="scope">
							<span>{{getStatusText(scope.row.maintainType)}}</span>
						</template>
                    </el-table-column>
                    <el-table-column align='center' prop="maintainUserNames" label="维保人员" width="200px"/>
                    <el-table-column fixed="right" align="center" prop="address" label="操作" width="80px">
						<template slot-scope="scope">
							<el-button @click="check(scope.row)" type="primary" size="small">查看</el-button>
						</template>
					</el-table-column>
                </el-table>
                <Pagination :total="total" :page.sync="current" :limit.sync="size" @pagination="dictionarPage" />
            </div>
        </div>
        <div class="null"></div>

        <elevator-maintenance-detail v-if="dioDetails" :initDioDetailsItem="dioDetailsItem"  @closeDialog="dioDetails=false"/>
    </el-dialog>
</template>
<script>
import Pagination from '@/components/Pagination'
import ElevatorMaintenanceDetail from './elevator-maintenance-detail.vue'
export default {
    props:{
        dialogTitle:String,
        start:Number|String,
        end:Number|String,
        openType:Number|String,
        //0、待处理  1、已签到 2、已维保 3、已驳回 4、已完成  5、超期后完成
        status:Number|String,
        newInterface:{
            type:Boolean,
            default:true
        }
    },
    components: {
        Pagination,
        ElevatorMaintenanceDetail
    },
    data() {
        return {
            dialogVisible:true,
            loading:false,
            //表格筛选信息
            name:'',
            code:'',
            maintainType:'',
            statusLoading:true,
            //表格数据及分页选项
            tableData: [],
            total:0,
            size:10,
            current:1,

            dioDetails: false,
			dioDetailsItem: null,
            //维保类型字典
            statusList:[],
        }
    },
    computed:{
        params(){
            const obj={
                current:this.current,
                size:this.size,
                eleName:this.name,
                eleCode:this.code,
                maintainType:this.maintainType,
                start:this.start,
                end:this.end,
                status:this.status
            }
            if(this.start&&this.end){
                obj.searchStartTime=[this.start,this.end]
            }
            return obj
        }
    },
    methods:{
        closeDialog() {
            this.$emit('closeDialog',{
                openType:this.openType
            })
        },
        reset(){
            this.name=''
            this.code=''
            this.maintainType=''
            this.getTable()
        },
        // 分页
        dictionarPage(data) {
            this.current = data.page
            this.size = data.limit
            this.getTable()
        },
        //获取状态字典
        async getDictionary(code='MAINTAIN_TYPE') {
            const res=await this.$http.get(`/api/system/web/dict/dictionary?code=${code}`)
            if(res.status==200&&res.data.code==200){
                this.statusList=res.data.data
            }
            this.statusLoading=false
        },
        getStatusText(status){
            const list=this.statusList.filter(item=>item.dictKey==status)
            return list.length>0?list[0].dictValue:status
        },
    
        getTable(){
            this.loading=true
            let url=this.newInterface?'/api/ele/web/maintainTask/getBigDataTaskList':'/api/ele/web/maintainTask/getList'
            this.$http.post(url, this.params).then((res) => {
                if(res.status==200&&res.data.code==200){
                    this.tableData=res.data.data.records.map((qs) => {
                        // qs.sosTypeName = this.getStatusText(qs.sosType)
                        // qs.sosStatusName = this.getSosStatusText(qs.sosStatus)
                        return qs
                    })
                    this.total=res.data.data.total
                    this.$nextTick(() => {
                        if (this.total > 0 && this.$refs.multipleTable) {
                            this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                        }
                    })
                }
                this.loading=false
            })
        },
        // 查看
        check(item) {
            this.dioDetailsItem = item
			this.dioDetails = true
        },

    },
    created(){
        this.getDictionary()
        .then(()=>{
            this.getTable()
        })
    }
}
</script>
<style lang="scss" scoped>
.scrollElement{
    margin:20px 0 0 0;
    // height: 72vh; 
    // overflow-y: scroll; 
    padding-right: 20px
}
.null{
    height: 90px;
}
.header,.header-item{
    display: flex;
}
.header{
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 20px;
}
.header-item{
    margin: 0 20px 20px 0;
    align-items: center;
    span{
        white-space: nowrap;
        margin-right: 8px;
    }
}

.scrollElement /deep/ .el-table__fixed-right {
    background: rgba(0,0,0,0) !important;
}
.scrollElement /deep/ .el-table__fixed-right >>> .el-table__row >>> .el-table__cell:last-child{
    background: #1e2d4f ;
}
</style>