<template>
	<div class="tbdod new-bigmianban">
		<div id="particles-js" class="particles"></div>
		<!-- 顶部横栏 -->
		<div class="topBg">
			<div class="topBgBox2">
                <el-image class="top-left-img" :src="require('@/assets/newBigmianban/top-left.png')" fit="cover" />
            </div>
			<div class="topBgBoxCent">
				<div class="top">
					<el-image v-if="$store.state.isZx" style="width: 38px; height: 38px;margin: 0 20px;"
						:src="require('@/assets/zhongxin.png')" fit="cover" />
					<h2 class="title">
						<!-- <span v-if="$store.state.isZx">中信数字化电梯物联网监管系统</span> -->
						<span>锦地梯控物联网监管系统</span>
					</h2>
				</div>
			</div>
			<div class="topBgBox1">
				<el-image class="top-right-img" :src="require('@/assets/newBigmianban/top-right.png')" fit="cover" />
				<div class="top-right">
					<!-- <div class="back-btn" @click="back">回到平台</div> -->
					<div @click="handleFullScreen" :class="[fullscreen?'':'close-full-Screen','full-Screen']"></div>
					<div class="top-time">
						<div class="top-time-date">{{timeVal[1]}}</div>
						<div>{{timeVal[0]}}</div>
					</div>
					<div class="day">{{day}}</div>
				</div>
			</div>
		</div>
		<div class="map-container">
			<!-- echarts中国地图 -->
			<div v-show="!showMapGD" class="echarts-container">
				<div id="myChart" ref="myChart"></div>
				<div class="echarts-history">
					<!-- <el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item v-for="item in echartsHistory" :key="item.regionData.adcode" :to="item.regionData.adcode" @click.native="goRegion(item)">
							{{item.regionData.name}}
						</el-breadcrumb-item>
					</el-breadcrumb> -->
				</div>
			</div>
			<!-- 高德地图 -->
			<div v-show="showMapGD" class="mapGD-container">
				<div id="mapGD"></div>
				<!-- <el-button @click="backEcharts" class="back-echarts" type="primary">返回</el-button> -->
			</div>
			<div class="map-icon-box">
				<el-image class="map-icon" @click="mapRest" :src="require('@/assets/newBigmianban/reset.png')" fit="cover" />
				<el-image class="map-icon" @click="mapBack" :src="require('@/assets/newBigmianban/back.png')" fit="cover" />
				<el-image class="map-icon" @click="mapZoomAdd" :src="require('@/assets/newBigmianban/add.png')" fit="cover" />
				<el-image class="map-icon" @click="mapZoomReduce" :src="require('@/assets/newBigmianban/reduce.png')" fit="cover" />
			</div>
		</div>

        <!-- 左图表 -->
		<div class="left-box">
			<div class="left-box-a">
				<div class="box-img">
					<span>{{leftTitleA}}</span>
				</div>
				<div class="chart" @click="openRisk" id="left-box-a"></div>
			</div>
			<div class="left-box-b">
                <div class="title-container">
                    <div class="box-img">
                        <span>{{leftTitleB}}</span>
                    </div>
                    <date-btns ref="leftBoxB" @change="({time,dateVal})=>getMaintenancePerformance(time,dateVal)"></date-btns>
                </div>
				<div class="chart" id="left-box-b">
                    <div class="maintenance-list">
                        <div class="maintenance-item" @click="openMaintenanceResults(item)" v-for="(item,index) in maintenanceList" :key="index">
                            <img class="maintenance-img" :src="item.img" />
                            <div v-if="maintenanceData" class="maintenance-text">
                                <div>
									<animate-number :from="index==0?oldMaintenanceData.maintainNum||0:index==1?oldMaintenanceData.maintainTaskNum||0:index==2?oldMaintenanceData.maintainTaskNumber||0:oldMaintenanceData.failureRate||0" :to="index==0?maintenanceData.maintainNum:index==1?maintenanceData.maintainTaskNum:index==2?maintenanceData.maintainTaskNumber||0:maintenanceData.failureRate||0" :key="index==0?maintenanceData.maintainNum:index==1?maintenanceData.maintainTaskNum:index==2?maintenanceData.maintainTaskNumber:index==3?maintenanceData.failureRate:''" duration="1000">
									</animate-number>
                                    {{item.unit}}
                                </div>
                                <div>{{item.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
			<div class="left-box-c">
				<div class="title-container">
                    <div class="box-img">
                        <span>{{leftTitleC}}</span>
                    </div>
                    <date-btns ref="leftBoxC" @change="({time,dateVal})=>getMaintenanceAndRepair(time,dateVal)"></date-btns>
                </div>
				<div class="chart" id="left-box-c">
                    <div class="repair-list">
                        <div class="repair-item" @click="openMaintenanceItem(item)" v-for="(item,index) in repairList" :key="index">
                            <div v-if='repairData' class="repair-content">
                                <template v-if="index==0">
                                    <div><animate-number :from="oldRepairData.completionRate||1" :to="repairData.completionRate" :key="repairData.completionRate" duration="2000"></animate-number>%</div>
                                    <div><animate-number :from="oldRepairData.taskSize||0" :to="repairData.taskSize" :key="repairData.taskSize" duration="2000"></animate-number><span class="repair-unit">次</span></div>
                                </template>
                                <template v-else-if="index==1">
                                    <div><animate-number :from="oldRepairData.PendingMaintenance||0" :to="repairData.PendingMaintenance" :key="repairData.PendingMaintenance" duration="2000"></animate-number><span class="repair-unit">次</span></div>
                                </template>
                                <template v-else-if="index==2">
                                    <div><animate-number :from="oldRepairData.mainttenanceTreatment||0" :to="repairData.mainttenanceTreatment" :key="repairData.mainttenanceTreatment" duration="2000"></animate-number>%</div>
                                    <div><animate-number :from="oldRepairData.underMaintenance||0" :to="repairData.underMaintenance" :key="repairData.underMaintenance" duration="2000"></animate-number><span class="repair-unit">项</span></div>
                                </template>
                                <template v-else-if="index==3">
                                    <div><animate-number :from="oldRepairData.toBeRepaired||0" :to="repairData.toBeRepaired" :key="repairData.toBeRepaired" duration="2000"></animate-number></div>
                                </template>
                            </div>
                            <div class="repair-title">{{item}}</div>
                        </div>
                    </div>
                </div>
			</div>
		</div>
		<!-- 右图表 -->
		<div class="right-box">
			<div class="right-box-a">
				<div class="box-img">
					<span>{{rightTitleA}}</span>
				</div>
				<div class="chart" id="right-box-a" @click="($event)=>openseamlessItem($event)" 
				@mouseover="($event)=>mouseSeamlessItem($event,true)" 
				@mouseout="($event)=>mouseSeamlessItem($event,false)">
					<vue-seamless-scroll v-if="fnList.length>0" :data="fnList" :class-option="rescueClassOption" class="right-a-list-container">
						<div class="right-a-list">
                            <template v-if="fnList.length>0" v-for="(item,index) in fnList" >
								<div :key="index">
									<div v-if="item.fnStatus==1" :class="[rescueStatusObj[item.rescueStatus].class,'right-a-item seamless-row']"
									:data-obj="JSON.stringify(item)" :data-i="index" :data-type="1">
										<div class="sos"></div>
										<div class="right-a-title-container">
											<div class="right-a-title ellipsis">{{ item.rescueTitle }}</div>
											<div class="right-a-date">{{ item.rescueDate }}</div>
										</div>
										<div class="right-a-time-container">
											<img class="right-a-img" :src="rescueStatusObj[item.rescueStatus].img" />
											<div class="right-a-time">{{ item.rescueTime }}</div>
										</div>
										<div class="right-a-status">{{ rescueStatusObj[item.rescueStatus].value }}</div>
									</div>
									<div v-else :class="[faultStatusObj[item.faultStatus].class,'right-b-item seamless-row']"
									:data-obj="JSON.stringify(item)" :data-i="index" :data-type="2">
										<img class="right-b-img" :src="faultStatusObj[item.faultStatus].img" />
										<div class="right-b-status">{{ faultStatusObj[item.faultStatus].value }}</div>
										<div class="right-b-title-container">
											<div class="right-b-title ellipsis">{{ item.faultTitle }}</div>
											<div class="right-b-date">{{ item.faultDate }}</div>
										</div>					
									</div>
								</div>
                            </template>
						</div>
					</vue-seamless-scroll>
					<div v-else class="null-list">
						暂无数据
					</div>
				</div>
			</div>
			<div class="right-box-b">
                <div class="title-container">
                    <div class="box-img">
                        <span>{{rightTitleB}}</span>
                    </div>
					<date-btns ref="rightBoxB" @change="({time,dateVal})=>allEventStatisticsInit(time,dateVal)"></date-btns>
                </div>
				<transition name="fade" appear >
					<div v-show="rightEchartsBShow" class="chart" id="right-box-b"></div>
				</transition>
				<div class="average-operation-container">
					<div class="average-operation-content average-operation-content-1">
						<div class="average-operation-line"></div>
						<div class="average-operation-text">平均响应时长</div>
						<div class="average-operation-num-conainer">
							<span class="average-operation-num"><animate-number :from="getTimeVal(oldAverageOperation.pjxy||0).value" :to="getTimeVal(averageOperation.pjxy).value" :key="getTimeVal(averageOperation.pjxy).value" duration="1000"></animate-number></span>
							<span>{{getTimeVal(averageOperation.pjxy).unit}}</span>
						</div>
					</div>
					<div class="average-operation-content average-operation-content-2">
						<div class="average-operation-line"></div>
						<div class="average-operation-text">平均救援时长</div>
						<div class="average-operation-num-conainer">
							<span class="average-operation-num"><animate-number :from="getTimeVal(oldAverageOperation.pjjy||0).value" :to="getTimeVal(averageOperation.pjjy).value" :key="getTimeVal(averageOperation.pjjy).value" duration="1000"></animate-number></span>
							<span>{{getTimeVal(averageOperation.pjjy).unit}}</span>
						</div>
					</div>
				</div>
			</div>
			
		</div>

        <!-- 底部数据 -->
        <div class="footer">
            <div v-if="footerRes" class="footer-item" v-for="(item,index) in footerData" :key="index" @click="openLineVisible(index)">
                <div class="footer-num-container">
                    <!-- <span class="footer-num">{{item.unit?item.value:getTimeVal(item.value).value}}</span> -->
					<span class="footer-num">
						<span v-if="index==0" class="footer-num">
							<animate-number :from="item.unit?oldFooterRes.eleCount||0:getTimeVal(oldFooterRes.eleCount||0).value" :to="item.unit?footerRes.eleCount:getTimeVal(footerRes.eleCount).value" :key="item.unit?footerRes.eleCount:getTimeVal(footerRes.eleCount).value" duration="1000"></animate-number>
						</span>
						<span v-if="index==1" class="footer-num">
							<animate-number :from="item.unit?oldFooterRes.zeroFailureTime||0:getTimeVal(oldFooterRes.zeroFailureTime||0).value" :to="item.unit?footerRes.zeroFailureTime:getTimeVal(footerRes.zeroFailureTime).value" :key="item.unit?footerRes.zeroFailureTime:getTimeVal(footerRes.zeroFailureTime).value" duration="1000"></animate-number>
						</span>
						<span v-if="index==2" class="footer-num">
							<animate-number :from="item.unit?oldFooterRes.onlineCount||0:getTimeVal(oldFooterRes.onlineCount||0).value" :to="item.unit?footerRes.onlineCount:getTimeVal(footerRes.onlineCount).value" :key="item.unit?footerRes.onlineCount:getTimeVal(footerRes.onlineCount).value" duration="1000"></animate-number>
						</span>
						<span v-if="index==3" class="footer-num">
							<animate-number :from="item.unit?oldFooterRes.unOnlineCount||0:getTimeVal(oldFooterRes.unOnlineCount||0).value" :to="item.unit?footerRes.unOnlineCount:getTimeVal(footerRes.unOnlineCount).value" :key="item.unit?footerRes.unOnlineCount:getTimeVal(footerRes.unOnlineCount).value" duration="1000"></animate-number>
						</span>
						<span v-if="index==4" class="footer-num">
							<animate-number :from="item.unit?oldFooterRes.failureTaskCount||0:getTimeVal(oldFooterRes.failureTaskCount||0).value" :to="item.unit?footerRes.failureTaskCount:getTimeVal(footerRes.failureTaskCount).value" :key="item.unit?footerRes.failureTaskCount:getTimeVal(footerRes.failureTaskCount).value" duration="1000"></animate-number>
						</span>
						<span v-if="index==5" class="footer-num">
							<animate-number :from="item.unit?oldFooterRes.maintainTaskNum||0:getTimeVal(oldFooterRes.maintainTaskNum||0).value" :to="item.unit?footerRes.maintainTaskNum:getTimeVal(footerRes.maintainTaskNum).value" :key="item.unit?footerRes.maintainTaskNum:getTimeVal(footerRes.maintainTaskNum).value" duration="1000"></animate-number>
						</span>
					</span>
                    <span>{{item.unit?item.unit:getTimeVal(item.value).unit}}</span>
                </div>
                <div class="footer-img-container">
                    <img class="footer-img" :src="item.img" />
                </div>
                <div class="footer-text">{{item.text}}</div>
            </div>
        </div>


        <!-- 页面加载动画 -->
		<transition name="fade" appear leave-active-class="animate__animated animate__zoomOut">
			<img v-if="isLodin" class="lodinBack" src="@/assets/newBigmianban/lodin.gif" />
		</transition>
		<!-- 标注弹框 -->
		<MonitorInfo class="monitor-info" ref="MonitorInfo" v-if="MonitorInfoVisible" :eleInfo='eleInfo' :elevatorId="elevatorId"
		:deviceCode="deviceCode" @closeMonitor="closeMonitor" />
		<!-- 电梯弹框 -->
		<elevator-line v-if="lineVisible" :initLineStatus="initLineStatus" :initFaultStatus="initFaultStatus" @closeDialog="lineVisible=false" />
		<!-- 维保弹框 -->
		<elevator-maintenance v-if="elevatorMaintenanceVisible" 
		:start="elevatorMaintenanceType==1?'':elevatorMaintenanceType==2?maintenanceDate.start:repairDate.start" 
		:end="elevatorMaintenanceType==1?'':elevatorMaintenanceType==2?maintenanceDate.end:repairDate.end" :openType="elevatorMaintenanceType"
		:dialogTitle="elevatorMaintenanceTitle" :status="elevatorMaintenanceStatus" @closeDialog="elevatorMaintenanceClose"/>
		<!-- 故障记录弹框 -->
		<elevator-fault v-if="faultVisible" 
		:start="faultType==2?eventDate.start:startMaintainEleDown" 
		:end="faultType==2?eventDate.end:endMaintainEleDown" :openType="faultType"
		:dialogTitle="faultTitle" @closeDialog="faultClose" />
		<!-- 维保人员弹框 -->
		<maintenance-personnel v-if="personnelVisible" :start="maintenanceDate.start" :end="maintenanceDate.end" 
		:dialogTitle="personnelTitle" @closeDialog="personnelClose" />
		<!-- 报修弹框 -->
		<Repair v-if="repairVisible" :initTaskStatus="repairStatus" :title="repairTitle" 
		:start="repairDate.start" :end="repairDate.end" 
		@closeDialog="repairVisible=repairClose" />
		<!-- 风险统计弹框 -->
		<Risk v-if="riskVisible" @closeDialog="riskVisible=false" />
		<!-- 救援详情弹框 -->
		<rescue-detail v-if="rescueDetailVisible" :initDioDetailsItem="rescueDioDetailsItem" :detailsItem="rescueDetailsItem" :stateList="statusList" 
		@closeDialog="rescueDetailVisible=false" />
		<!-- 告警详情弹框 -->
		<elevator-warning-detail v-if="warningDetailVisible" :initDioDetailsItem="warningDioDetailsItem" :detailsItem="warningDetailsItem"  @closeDialog="warningDetailVisible=false"/>
        <!-- 告警弹框 -->
		<elevator-warning v-if="warningVisible" :start="eventDate.start" :end="eventDate.end" 
		:dialogTitle="warningTitle" @closeDialog="warningClose"/>
		<!-- 救援弹框 -->
		<Rescue v-if="rescueVisible" :dialogTitle="rescueTitle" 
		:start="eventDate.start" 
		:end="eventDate.end" 
		@closeDialog="rescueColse" />

		<!-- 警报弹窗 -->
		<sos-dialog></sos-dialog>
	</div>
</template>

<script>
	import 'animate.css';
    import { formatDate,secondToHMS,getTimeVal,getDay } from './untils';
	import bin1 from './com/bin.vue';
    import dateBtns from './com/date-btns.vue';
	import ElevatorLine from './dialog/elevator-line.vue';
	import ElevatorMaintenance from './dialog/elevator-maintenance.vue';
	import ElevatorFault from './dialog/elevator-fault.vue';
	import MaintenancePersonnel from './dialog/maintenance-personnel.vue';
	import Repair from './dialog/repair.vue';
	import Risk from './dialog/risk.vue';
	import RescueDetail from './dialog/rescue-detail.vue'
	import ElevatorWarningDetail from './dialog/elevator-warning-detail.vue'
	import ElevatorWarning from './dialog/elevator-warning.vue';
	import Rescue from './dialog/rescue.vue';
	import SosDialog from './com/sos-dialog.vue'
	import MonitorInfo from '../dtsj/dtjk/components/monitorInfo.vue'
	import vueSeamlessScroll  from 'vue-seamless-scroll'
	// import AnimateNumber from 'vue-animate-number'
	import * as echarts from 'echarts';
	let myChart=null
	let mapGD=null
	let markers=[]
	let isOne = true
	export default {
		data() {
			return {
				// 加载动画
				isLodin: true,
				//echarts相关数据
				//地图json数据
				echartsJson:{
					features:[],
					type: "FeatureCollection"
				},
				//地图标注设备数据
				convertData:[],
				//当前地图区域信息对象
				regionData:{},
				initRegionData:{},
				//地图查询历史
				echartsHistory:[],
				//地图标注渐变区间值
				colorIntervalValue:[
					{
						
					}
				],

				//高德地图相关
				//地图开关
				showMapGD:false,
				//地图中心点
				mapCenterGD:null,
				// 标注弹框数据
				eleInfo: null, //总数据
				elevatorId: '', // 电梯id
				deviceCode: '', // 设备号
				//标注弹框开关
				MonitorInfoVisible: false,
				//ws定时器对象
				facilityTimer:null,

				
				//默认统计区间:1周、2月、3年
				dateVal:2,
				startMaintainEleDown:0,
				endMaintainEleDown:0,

				//图表标题
				leftTitleA:'电梯风险分布',
				leftTitleB:'维保成绩',
				leftTitleC:'维保维修情况',
				rightTitleA:'事件中心',
				rightTitleB:'事件信息统计',

				//风险等级
				leftEchartsA:null,
                riskData:{},
                //维保成绩
				maintenanceData:null,
				oldMaintenanceData:{},
                maintenanceDate:null,
                maintenanceList:[
                    {
                        name:'维保人员',
                        unit:'',
                        img:require("@/assets/newBigmianban/maintenance-1.gif")
                    },
                    {
                        name:'超期维保',
                        unit:'次',
                        img:require("@/assets/newBigmianban/maintenance-2.gif")
                    },
                    {
                        name:'超期保养',
                        unit:'台',
                        img:require("@/assets/newBigmianban/maintenance-3.gif")
                    },
                    {
                        name:'故障率/月',
                        unit:'%',
                        img:require("@/assets/newBigmianban/maintenance-4.gif")
                    },
                ],
                //维修情况
                repairData:null,
				oldRepairData:{},
                repairDate:null,
				repairDateVal:this.dateVal,
                repairList:['维保完成率','待维保任务','维修处理中','待维修处理'],


                //事件中心列表
                fnList:[],
				fnActiveIndex:-1,
				//事件中心配置
				rescueClassOption:{
					step:0.5,
					limitMoveNum:4,
					autoPlay:true,
				},
				//实时救援状态
				rescueStatusObj:{
					1:{
						value:'待处理',
						class:'rescue-status-1',
						img:require("@/assets/newBigmianban/surface-1.png"),
					},
					2:{
						value:'救援中',
						class:'rescue-status-2',
						img:require("@/assets/newBigmianban/surface-1.png"),
					},
                    3:{
						value:'救援中',
						class:'rescue-status-2',
						img:require("@/assets/newBigmianban/surface-1.png"),
					},
					4:{
						value:'救援完成',
						class:'rescue-status-3',
						img:require("@/assets/newBigmianban/surface-2.png"),
					}
				},
				//实时故障状态
				faultStatusObj:{
					4:{
						value:'急停',
						class:'fault-status-4',
						img:require("@/assets/newBigmianban/fault-4.png"),
					},
					5:{
						value:'困人',
						class:'fault-status-5',
						img:require("@/assets/newBigmianban/fault-5.png"),
					},
					7:{
						value:'电梯停电',
						class:'fault-status-7',
						img:require("@/assets/newBigmianban/fault-7.png"),
					},
					8:{
						value:'关门异常',
						class:'fault-status-8',
						img:require("@/assets/newBigmianban/fault-8.png"),
					},
					9:{
						value:'超速',
						class:'fault-status-9',
						img:require("@/assets/newBigmianban/fault-9.png"),
					},
					10:{
						value:'非平层停梯',
						class:'fault-status-10',
						img:require("@/assets/newBigmianban/fault-10.png"),
					},
					11:{
						value:'冲顶',
						class:'fault-status-11',
						img:require("@/assets/newBigmianban/fault-11.png"),
					},
					12:{
						value:'蹲底',
						class:'fault-status-12',
						img:require("@/assets/newBigmianban/fault-12.png"),
					},
					13:{
						value:'电动车入梯',
						class:'fault-status-13',
						img:require("@/assets/newBigmianban/fault-13.png"),
					},
					95:{
						value:'开门行梯',
						class:'fault-status-95',
						img:require("@/assets/newBigmianban/fault-95.png"),
					},
				},
                //事件信息统计
				rightEchartsBShow:true,
                rightEchartsB:null,
				eventDateVal:this.dateVal,
                eventDate:null,
                //底部数据图片
                footerData:[
                    {
                        value:0,
                        text:'总电梯数',
                        img:require("@/assets/newBigmianban/footer-1.gif"),
                        unit:'台'
                    },
                    {
                        value:0,
                        text:'零故障运行',
                        img:require("@/assets/newBigmianban/footer-7.gif"),
                        unit:''
                    },
                    {
                        value:0,
                        text:'在线',
                        img:require("@/assets/newBigmianban/footer-2.gif"),
                        unit:'台'
                    },
                    {
                        value:0,
                        text:'离线',
                        img:require("@/assets/newBigmianban/footer-6.gif"),
                        unit:'台'
                    },
                    {
                        value:0,
                        text:'故障中',
                        img:require("@/assets/newBigmianban/footer-4.gif"),
                        unit:'台'
                    },
                    {
                        value:0,
                        text:'累计维保',
                        img:require("@/assets/newBigmianban/footer-5.gif"),
                        unit:'次'
                    },
                ],
				footerRes:null,
				oldFooterRes:{},
				//平均运行统计
				averageOperation:{
					pjxy:0,
					pjjy:0
				},
				oldAverageOperation:{},
                //时间定时器
                timeVal:[],
				day:'',
                timer:null,

                //全屏开关
                fullscreen:false,

				//电梯数据弹窗开关
				lineVisible:false,
				initLineStatus:'',
				initFaultStatus:'',

                //维保记录弹框
				elevatorMaintenanceVisible:false,
				elevatorMaintenanceTitle:'',
				elevatorMaintenanceType:'',
				elevatorMaintenanceStatus:'',

				//故障记录弹框
				faultVisible:false,
				faultType:1,
				faultTitle:'',

				//维保人员弹窗
				personnelVisible:false,
				personnelTitle:'维保人员',

				//报修弹框
				repairVisible:false,
				repairStatus:'',
				repairTitle:'',

				//风险统计弹框
				riskVisible:false,

				//救援详情弹框
				rescueDetailVisible:false,
				rescueDioDetailsItem:{},
				rescueDetailsItem: {
					seleId: 1,
					seleArr: [{
						value: 1,
						label: '报警消息'
					}, {
						value: 2,
						label: '运行参数'
					}, {
						value: 3,
						label: '处置流程'
					}]
				},
				statusList:[],
            	typeList:[],

				//告警详情弹框
				warningDetailVisible:false,
				warningDetailsItem:{
					seleId: 1,
					seleArr: [{
						value: 1,
						label: '报警消息'
					}, {
						value: 2,
						label: '运行参数'
					}, {
						value: 3,
						label: '处置流程'
					}]
				},
				warningDioDetailsItem:null,
				sosStatusOptions:[
					{
						value:1,
						label:'待处理'
					},
					{
						value:2,
						label:'已关闭'
					},
					{
						value:3,
						label:'进行中'
					},
					{
						value:4,
						label:'已完成'
					},
				],

				//告警弹窗开关
				warningType:1,
				warningVisible:false,
				warningTitle:'',

				//救援开关
				rescueVisible:false,
				rescueTitle:'',

				rightEchartsBHasClick:false,

				timerId:null,
				angle:0,

				timeOutEr:null,
			}
		},
		watch:{
			repairData(value,oldValue){
				if(oldValue){
					this.oldRepairData=oldValue
				}
			},
			maintenanceData(value,oldValue){
				if(oldValue){
					this.oldMaintenanceData=oldValue
				}
			},
			averageOperation(value,oldValue){
				if(oldValue){
					this.oldAverageOperation=oldValue
				}
			},
			footerRes(value,oldValue){
				if(oldValue){
					this.oldFooterRes=oldValue
				}
			},
		},
		computed:{
		

		},
		components: {
			bin1,
			MonitorInfo,
			vueSeamlessScroll,
            dateBtns,
			ElevatorLine,
			ElevatorMaintenance,
			ElevatorFault,
			MaintenancePersonnel,
			Repair,
			Risk,
			RescueDetail,
			ElevatorWarningDetail,
			ElevatorWarning,
			Rescue,
			SosDialog
		},
		methods:{
			//绘制echarts地图
			// async echartMapInit(regionData={name:'中国',adcode:0},resetMap=true,historyData){
			async echartMapInit(regionData=this.initRegionData,resetMap=true,historyData){
                // myChart.showLoading({ text: '正在加载数据' });
                
				if(historyData){
					this.regionData=historyData.regionData
					this.echartsJson=historyData.echartsJson
					this.convertData=historyData.convertData
				}else{
					this.regionData=regionData
					const res=await this.$http.get(`/api/ele/web/bigScreen/map/elevator?adcode=${this.regionData.adcode}`)
					if(res.status==200&&res.data.code==200){
						this.echartsJson=res.data.data.aliData
						await this.getConvertData(res.data.data.countData.filter(item=>item.count>0))
					}
					
				}
				//调用我们通过json对象注册的地图
				echarts.registerMap('China', this.echartsJson);
				//记录历史
				this.recordHistory({regionData:this.regionData,echartsJson:this.echartsJson,convertData:this.convertData})
				//设置地图选项
				this.setMapOption(resetMap,regionData.adcode)
				//文件加载的动画
				// myChart.hideLoading();
			},
			//绑定echarts点击事件
			bindClick(){
				myChart.on('click', (e)=>{
					const regionData=this.getRegionData(e.name)
					if(regionData.level=="district"||(regionData.childrenNum==0)){
						this.showMapGD=true
						this.mapCenterGD=regionData.center
						this.mapInitGD()
					}else{
						this.echartMapInit(regionData)
					}
				})
			},
			//获取分散点设备数量
			async getConvertData(countData){
				// if(countData.length>0&&countData[0].level=="city"&&countData[0].name=="直辖区"){
				// 	this.echartMapInit(countData[0])
				// 	return
				// }
				// this.echartsJson.features.map((item)=>{
				// 	console.log(item.properties.name,item.properties.adcode)
				// })
				this.convertData=countData.map(item=>{
					let data=this.echartsJson.features.filter(echartsItem=>echartsItem.properties.adcode==item.adcode)[0]
					const value=[...data.properties.center,item.count]
					return {
						name:data.properties.name,
						value
					}
				})
				return this.convertData
			},
			//根据地区名称获取相关信息
			getRegionData(name){
				const res= this.echartsJson.features.filter(item=>item.properties.name==name)
				return res.length>0?res[0].properties:{}
			},
			//记录地图查询历史
			recordHistory(data){
				const level=(data.regionData.adcode==100000||data.regionData.adcode==0)?0:data.regionData.level=="province"?1:data.regionData.level=="city"?2:3
				this.echartsHistory.splice(level,this.echartsHistory.length-level,{...data,level})
			},
			//更新地图区域
			goRegion(item){
				this.echartMapInit(undefined,true,item)
			},
			//设置事件
			setGeoroam(){
				myChart.on("georoam", (params)=> {
					var option = myChart.getOption(); //获得option对象
					if (params.zoom != null && params.zoom != undefined) {
						//捕捉到缩放时
						option.geo[0].zoom = option.series[0].zoom; //下层geo的缩放等级跟着上层的geo一起改变
						option.geo[0].center = option.series[0].center; //下层的geo的中心位置随着上层geo一起改变
                    } else {
						//捕捉到拖曳时
						option.geo[0].center = option.series[0].center; //下层的geo的中心位置随着上层geo一起改变
					}
					myChart.setOption(option,{lazyUpdate:true}); //设置option
				});
			},
			//配置echarts选项
			setMapOption(resetMap=true,adcode){
				let imgSrc=require("../../assets/newBigmianban/map-icon.png");
				imgSrc=`image://${imgSrc}`
				myChart = myChart?myChart:echarts.init(document.getElementById('myChart'));
				//地图开始
				const option = {
					//设置背景颜色
					// backgroundColor: '#020933',
					geo: {
						map: 'China',//地图为刚刚设置的China
						aspectScale:0.8, //长宽比
						// zoom:1,//当前视角的缩放比例
						roam: true,//是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启
						animationDurationUpdate:0,
						top:adcode==0?170:30,
						//地图上文字
						label: {
							normal: {
								show: true,//是否显示标签
								textStyle: {
									color: '#fff',
									fontSize: 12,
								},
								formatter: (p) => {
									switch (p.name) {
										case "内蒙古自治区":
										p.name = "内蒙古";
										break;
										case "西藏自治区":
										p.name = "西藏";
										break;
										case "新疆维吾尔自治区":
										p.name = "新疆";
										break;
										case "宁夏回族自治区":
										p.name = "宁夏";
										break;
										case "广西壮族自治区":
										p.name = "广西";
										break;
										case "香港特别行政区":
										p.name = "";
										break;
										case "澳门特别行政区":
										p.name = "";
										break;
										default:
										break;
									}
									return p.name;
								}
							},
							emphasis: {
								textStyle: {
									color: '#fff',
								},
							},
						},
						itemStyle: {//地图区域的多边形 图形样式
							// normal: {
							// 	borderColor: 'rgba(147, 235, 248, 1)',
							// 	areaColor: '#2579c3',//地区颜色
							// 	shadowColor: '#182f68',//阴影颜色
							// 	shadowOffsetX: 0,//阴影偏移量
							// 	shadowOffsetY: 0,//阴影偏移量
							// },
							normal: {
								borderColor: 'rgba(147, 235, 248, 1)',
								borderWidth: 1,
								areaColor: {
									type: 'radial',
									x: 0.5,
									y: 0.5,
									r: 0.8,
									colorStops: [{
										offset: 0,
										color: '#004196' // 0% 处的颜色
									}, {
										offset: 1,
										color: '#397ace' // 100% 处的颜色
									}],
									globalCoord: false // 缺省为 false
								},
								shadowColor: 'rgba(128, 217, 248, 1)',
								// shadowColor: 'rgba(255, 255, 255, 1)',
								shadowOffsetX: -2,
								shadowOffsetY: 2,
								shadowBlur: 10
							},
							emphasis: {
								areaColor: '#04459a',//地区颜色
								label: {
									show: false,//是否在高亮状态下显示标签
								},
								borderWidth: 1.0,
								shadowBlur: 25,
							},
						},
					},
					series: [//数据系列
						// {
						// 	type: 'map',//地图类型
						// 	zoom: 1.1,//当前视角的缩放比例
						// 	//是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启
						// 	roam: true,
						// 	animationDurationUpdate:0,
						// 	map: 'China', //使用中国地图
						// 	//地图上文字
						// 	label: {
						// 		normal: {
						// 			show: true,//是否显示标签
						// 			textStyle: {
						// 				color: '#fff',
						// 			},
						// 		},
						// 		emphasis: {
						// 			textStyle: {
						// 				color: '#fff',
						// 			},
						// 		},
						// 	},
						// 	//地图区域的多边形 图形样式
						// 	itemStyle: {
						// 		normal: {
						// 			borderColor: '#2ab8ff',
						// 			borderWidth: 1.5,
						// 			areaColor: '#12235c',
						// 		},
						// 		emphasis: {
						// 			areaColor: '#2AB8FF',
						// 			borderWidth: 0,
						// 		},
						// 	},
						// },
						{
							type: 'effectScatter',
							coordinateSystem: 'geo',
							geoIndex: 0,
							symbol: 'circle',
							symbolSize: 6,
							showEffectOn: 'render',
							rippleEffect: {
								brushType: 'stroke',
								scale: 10
							},
							hoverAnimation: true,
							label: {
								show: false,
								formatter: name => {
									return name.data[2];
								},
								position: 'right',
								color: '#fff',
								fontSize: 14,
								distance: 10
							},
							itemStyle: {
								color: 'rgba(0, 255, 246, 1)',
							},
							zlevel: 1,
							data: this.convertData,
						}, {
							type: 'effectScatter',
							coordinateSystem: 'geo',
							geoIndex: 0,
							silent: true,
							symbol: 'circle',
							symbolSize: 4,
							showEffectOn: 'render',
							rippleEffect: {
								brushType: 'stroke',
								scale: 6
							},
							hoverAnimation: true,
							label: {
								formatter: '',
								position: 'right',
								color: '#fff',
								fontSize: 14,
								distance: 10,
								show: !0,
							},
							itemStyle: {
								color: 'rgba(255, 255, 255, 0.8)',
							},
							zlevel: 1,
							data: this.convertData,
						},
						{
							//设置为分散点
							type: 'scatter',
							zoom: 1.2,//当前视角的缩放比例
							//是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启
							roam: true,
							animationDurationUpdate:0,
							//series坐标系类型
							coordinateSystem: 'geo',
							//设置图形 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow'
							symbol: imgSrc,
							symbolOffset:[0, '-40%'],
							// //标记的大小，可以设置成诸如 10 这样单一的数字，也可以用数组分开表示宽和高，例如 [20, 10] 表示标记宽为20，高为10
							// symbolSize: [30,33],
							symbolSize: function (value) {
								console.log(value[2],'value11111');
								if(value[2] > 999){
									return [50, 53];
								}
								return [30, 33];
							},
							//气泡字体设置
							label: {
								normal: {
									show: true,//是否显示
									textStyle: {
										fontWeight:'bold',
										color: '#0063e9',//字体颜色
										fontSize: 13,//字体大小
									},
									//返回气泡数据
									formatter (value){
										// return value.data.value[2] > 999 ? '999+' : value.data.value[2]
										return value.data.value[2]
									}
								},
								emphasis: { //动态展示的样式
									// color:'#fff',
									// fontSize: 15,//字体大小
									//返回气泡数据
									formatter (value){
										return value.data.value[2]
									}
								},
							},
							itemStyle: {
								normal: {
									color: '#1518E5', //标志颜色
									borderColor : '#fff'
								}
							},
							//给区域赋值
							data: this.convertData,
							showEffectOn: 'render',//配置何时显示特效。可选：'render' 绘制完成后显示特效。'emphasis' 高亮（hover）的时候显示特效。
							rippleEffect: {//涟漪特效相关配置。
								brushType: 'fill'//波纹的绘制方式，可选 'stroke' 和 'fill'
							},
							hoverAnimation: true,//是否开启鼠标 hover 的提示动画效果。
							zlevel: 2//所属图形的 zlevel 值
						},
					],
				};
				if(resetMap){
					option.geo.zoom=adcode==0?1.3:1
				}
				myChart.setOption(option,resetMap);
			},
			//初始化高德地图
			mapInitGD(){
				this.$nextTick(() => {
					mapGD = new AMap.Map('mapGD', {
						mapStyle: "amap://styles/darkblue",
						rotateEnable: true,
						pitchEnable: true,
						zoom: 13,
						pitch: 50,
						rotation: -15,
						viewMode: '3D', //开启3D视图,默认为关闭
						zooms: [2, 20],
						center:this.mapCenterGD
					})
					if(this.timeOutEr){
						clearTimeout(this.timeOutEr)
					}
					this.timeOutEr=setTimeout(() => {
						this.getFacility()
						if(this.facilityTimer){
							clearInterval(this.facilityTimer)
						}
						this.facilityTimer = setInterval(() => {
							this.getFacility()
						}, 59000)
					}, 1000)
				})
			},
			//返回echart地图
			backEcharts(){
				this.showMapGD=false
			},
			//获取地图设备数据
			getFacility() {
				// if (this.socketApi.isheart) {
				// 	let agentData = {
				// 		act: 'ma_get_ele_distribution',
				// 		token: sessionStorage.getItem('AdminToken')
				// 	}
				// 	this.socketApi.sendSock(agentData, (e) => {
				// 		if (e.cmd == 'ma_get_ele_distribution' && e.status) {
				// 			let data = JSON.parse(e.data)
				// 			this.eleDistri = data.eleDistributionTop
				// 			this.liftArr = data.eleList
				// 			this.setMapMarker(data.eleList)
				// 		}
				// 	})
				// }
				
				this.$http
					.post(`/api/ele/web/elevatorInfo/getEleDistribution`, {})
					.then((res) => {
					if (res.data.success) {
						this.eleDistri = res.data.data.eleDistributionTop
						this.liftArr = res.data.data.eleList
						this.setMapMarker(res.data.data.eleList)
					}
				})
			},
			//关闭信息窗体
			closeInfoWindow() {
				mapGD.clearInfoWindow();
			},
			//获取地图自定义窗体
			getContent(qs,isStatus) {
				var info = document.createElement("div");
				var top = document.createElement("div");
				var closeX = document.createElement("img");
				info.className = `custom-info ${isStatus.class}`;
				info.style.width = "237px";
				closeX.className = "close-x";
				top.className = "info-top";
				closeX.src = require("../../assets/newBigmianban/close.png");
    			closeX.onclick = this.closeInfoWindow;
				top.appendChild(closeX);
    			info.appendChild(top);
				var middle = document.createElement("div");
				middle.className = "info-content";
				middle.innerHTML = `<div class="info-name">${qs.name}</div>
									<div class="info-code">电梯注册代码： ${qs.code}</div>
									<div class="info-address">安装位置： ${qs.eleAddress}</div>`;
				info.appendChild(middle);
				return info
            },
			//设置标注
			setMapMarker(data=[]) {
				// mapGD.remove(markers)
				mapGD.clearMap()
				// data.forEach((qs) => {
				// 	if (qs.latitude && qs.longitude) {
				// 		let isStatus = this.getStatus(qs.status)

				// 		let icon = new AMap.Icon({
				// 			size: new AMap.Size(22, 31), // 图标尺寸
				// 			image: isStatus.icon, // Icon的图像
				// 			imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
				// 			imageSize: new AMap.Size(22, 31) // 根据所设置的大小拉伸或压缩图片
				// 		});

				// 		let marker = new AMap.Marker({
				// 			position: [
				// 				qs.longitude,
				// 				qs.latitude
				// 			], //位置
				// 			icon: icon, //自定义中端帽子的图标
				// 		})
				// 		// 设置标题
				// 		marker.setTitle('电梯名称:' + qs.name + ';设备号:' + qs.deviceCode)
				// 		markers.push(marker)
				// 		mapGD.add(marker) //添加到地图
				// 		let content = '<div style="background-color:' + isStatus.color +
				// 			';padding: 10px;color:white;">' + qs.name + '(' + isStatus.lab + ')' +
				// 			'<br/>AI摄像头ID:' + qs.deviceCode +
				// 			'<br/>监察识别码:' + qs.rescueCode +
				// 			'<br/>电梯注册代码:' + qs.code +
				// 			'<br/>安装位置:' + qs.eleAddress + '</div>'
				// 		let infoWindow = new AMap.InfoWindow({
				// 			isCustom: true,
				// 			//创建信息窗体
				// 			content: this.getContent(qs,isStatus),
				// 			// content,
				// 			offset: new AMap.Pixel(-30, -40),
				// 		})

				// 		var onMarkerOver = function(e) {
				// 			infoWindow.open(mapGD, e.target.getPosition()) //打开信息窗体
				// 		}
				// 		var onMarkerOut = function() {
				// 			infoWindow.close()
				// 		}
				// 		marker.on('mouseover', onMarkerOver) //绑定mouseover事件
				// 		// marker.on('mouseout', onMarkerOut) //绑定mouseout事件
				// 		let that = this
				// 		var onMarkerClick = function(e) {
				// 			that.eleInfo = qs
				// 			that.elevatorId = qs.id
				// 			that.deviceCode = qs.deviceCode
				// 			that.MonitorInfoVisible = true
				// 		}
				// 		marker.on('click', onMarkerClick) //绑定click事件
				// 	}
				// })
				if (this.cluster) {
					this.cluster.setMap(null);
				}
				const points=data.map(item=>({lnglat:[item.longitude,item.latitude],otherData:item}))
				const count=points.length
				var _renderMarker = (context)=> {
					let qs=context.data[0].otherData
					//设置图标
					let isStatus = this.getStatus(qs.status)
					context.marker.setIcon(new AMap.Icon({
						size: new AMap.Size(22, 31), // 图标尺寸
						image: isStatus.icon, // Icon的图像
						imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
						imageSize: new AMap.Size(22, 31) // 根据所设置的大小拉伸或压缩图片
					}))
					// 设置标题
					context.marker.setTitle('电梯名称:' + qs.name + ';设备号:' + qs.deviceCode)
					//设置点击事件
					context.marker.on('click',()=>{
						this.eleInfo = qs
						this.elevatorId = qs.id
						this.deviceCode = qs.deviceCode
						this.MonitorInfoVisible = true
					})
					//移入移出窗口
					let infoWindow = new AMap.InfoWindow({
						isCustom: true,
						//创建信息窗体
						content: this.getContent(qs,isStatus),
						offset: new AMap.Pixel(-32, -40),
					})
					context.marker.on('mouseover', (e)=> {
						infoWindow.open(mapGD, e.target.getPosition()) 
					}) 
					context.marker.on('mouseout', ()=> {
						infoWindow.close()
					}) 
				}
				var _renderClusterMarker = function (context) {
					var div = document.createElement('div');
					div.innerHTML = `<div class="map-marker-icon">${context.count}</div>`;
					context.marker.setOffset(new AMap.Pixel(0,0));
					context.marker.setContent(div)
				};
				this.cluster = new AMap.MarkerCluster(mapGD, points ,{
					gridSize: 60, 
					renderClusterMarker: _renderClusterMarker,
					renderMarker: _renderMarker,
				});
				//设置聚合点点击事件
				this.cluster.on('click', (item) => {
					//此处是通过包含点的数量判断是否是聚合点，不是聚合点就执行上方单个点的点击方式
					if(item.clusterData.length <= 1) {
						return;
					}
					//这里是计算所有聚合点的中心点
					let alllng = 0, alllat = 0;
					for(const mo of item.clusterData) {
						alllng += mo.lnglat.lng;
						alllat += mo.lnglat.lat;
					}
					const lat = alllat / item.clusterData.length;
					const lng = alllng / item.clusterData.length;
					//这里是放大地图，此处写死了每次点击放大的级别，可以根据点的数量和当前大小适应放大，体验更佳
					mapGD.setZoomAndCenter(mapGD.getZoom() + 2, [lng, lat]);
				});
				if (isOne) {
					isOne = false
					// mapGD.setFitView()
				}
			},
			//关闭标注弹窗
			closeMonitor() {
				this.MonitorInfoVisible = false
			},
            //获取标注状态信息
			getStatus(status) {
				let obj
				switch (status) {
					case 0:
						obj = {
							class:'window-status-0',
							lab: '离线',
							color: '#4c4c4c',
							icon: require('@/assets/newBigmianban/lx.png')
						}
						break;
					case 1:
						obj = {
							class:'window-status-1',
							lab: '在线',
							color: '#72D9FF',
							icon: require('@/assets/newBigmianban/zc.png')
						}
						break;
					case 2:
						obj = {
							class:'window-status-2',
							lab: '发生了报警',
							color: '#ffa0a0',
							icon: require('@/assets/newBigmianban/bj.png')
						}
						break;
					case 3:
						obj = {
							class:'window-status-3',
							lab: '故障',
							color: '#ff6666',
							icon: require('@/assets/newBigmianban/gz.png')
						}
						break;
					case 4:
						obj = {
							class:'window-status-4',
							lab: '即将年检',
							color: '#ffbe66',
							icon: require('@/assets/newBigmianban/nj.png')
						}
						break;
					case 5:
						obj = {
							class:'window-status-6',
							lab: '即将合同到期',
							color: '#759119',
							icon: require('@/assets/newBigmianban/dq.png')
						}
						break;
				}
				return obj
			},
            //返回平台
			back() {
				this.$router.push({
					path:'/dashboard'
				})
				// window.history.go(-1)
			},

			getCirlPoint(x0, y0, r, angle) {
				let x1 = x0 + r * Math.cos(angle * Math.PI / 180)
				let y1 = y0 + r * Math.sin(angle * Math.PI / 180)
				return {
					x: x1,
					y: y1
				}
			},
			drawRiskLevel(){
				this.angle +=3
				let angle=this.angle
				let getCirlPoint=this.getCirlPoint
				const total=this.riskData.level_1+this.riskData.level_2+this.riskData.level_3+this.riskData.level_4
                const _total=this.riskData.level_3+this.riskData.level_4
				let bgColor = 'rgba(0,0,0,0)';
				let title = '低风险';
                //保留两位小数
				let titleVal = !_total&!_total?0:(_total/total).toFixed(2)*100;
				let echartData = [{
						name: "Ⅰ级风险",
						value: this.riskData.level_1,
						color:'#B51000'
					},
					{
						name: "Ⅱ级风险",
						value: this.riskData.level_2,
						color:'#FFEA00'
					},
					{
						name: "Ⅲ级风险",
						value: this.riskData.level_3,
						color:'#00214A'
					},
					{
						name: "Ⅳ级风险",
						value: this.riskData.level_4,
						color:'#0F8200'
					}
				];
				echartData=echartData.filter(item=>item.value>0)

				let formatPercentage = function(num) {
					return `${((num/total*100).toFixed())}%`;
				}
				const option = {
					backgroundColor: bgColor,
					avoidLabelOverlap:true,
					color: echartData.map(item=>item.color),
					title: [{
						text: '{val|'+titleVal+'%}\n{name|' + title + '}',
						top: 'center',
						left: 'center',
						textStyle: {
							rich: {
								val: {
									fontSize: 20,
									// fontFamily: 'led regular',
									fontWeight: 'bold',
									color: '#20DBFD',
								},
								name: {
									fontSize: 14,
									fontWeight: 'normal',
									color: '#fff',
									padding: [2, 0]
								}
								
							}
						}
					}],
					series: [{
						type: 'pie',
						radius: ['40%', '50%'],
						center: ['50%', '50%'],
						data: echartData,
						hoverAnimation: false,
						itemStyle: {
							normal: {
								borderColor: bgColor,
								borderWidth: 2
							}
						},
						// labelLine: {
						// 	show:false,
						// 	normal: {
						// 		length: 20,
						// 		length2: 90,
						// 		lineStyle: {
						// 			color: '#fff'
						// 		}
						// 	}
						// },

						label: {
							normal: {
								formatter: params => {
									return (
										'{icon|●}{name|' + params.name + '}{value|' +
										formatPercentage(params.value) + '}'
									);
								},
								// padding: [0 , -100, 25, -100],
								rich: {
									icon: {
										fontSize: 16
									},
									name: {
										fontSize: 14,
										padding: [0, 10, 0, 4],
										color: '#fff'
									},
									value: {
										fontSize: 14,
										fontWeight: 'bold',
										color: '#fff'
									}
								}
							}
						},
					},
				// 紫色
				{
						name: "ring5",
						type: 'custom',
						coordinateSystem: "none",
						renderItem: function(params, api) {
							return {
								type: 'arc',
								shape: {
									cx: api.getWidth() / 2,
									cy: api.getHeight() / 2,
									r: Math.min(api.getWidth(), api.getHeight()) / 2 * 0.3,
									startAngle: (0 + angle) * Math.PI / 180,
									endAngle: (90 + angle) * Math.PI / 180
								},
								style: {
									stroke: "#8383FA",
									fill: "transparent",
									lineWidth: 1.5
								},
								silent: true
							};
						},
						data: [0]
					}, {
						name: "ring5", //紫点
						type: 'custom',
						coordinateSystem: "none",
						renderItem: function(params, api) {
							let x0 = api.getWidth() / 2;
							let y0 = api.getHeight() / 2;
							let r = Math.min(api.getWidth(), api.getHeight()) / 2 * 0.3;
							let point = getCirlPoint(x0, y0, r, (90 + angle))
							return {
								type: 'circle',
								shape: {
									cx: point.x,
									cy: point.y,
									r: 3
								},
								style: {
									stroke: "#8450F9", //绿
									fill: "#8450F9"
								},
								silent: true
							};
						},
						data: [0]
					},
					// 蓝色

					{
						name: "ring5",
						type: 'custom',
						coordinateSystem: "none",
						renderItem: function(params, api) {
							return {
								type: 'arc',
								shape: {
									cx: api.getWidth() / 2,
									cy: api.getHeight() / 2,
									r: Math.min(api.getWidth(), api.getHeight()) / 2 * 0.3,
									startAngle: (180 + angle) * Math.PI / 180,
									endAngle: (270 + angle) * Math.PI / 180
								},
								style: {
									stroke: "#4386FA",
									fill: "transparent",
									lineWidth: 1.5
								},
								silent: true
							};
						},
						data: [0]
					},
					{
						name: "ring5", // 蓝色
						type: 'custom',
						coordinateSystem: "none",
						renderItem: function(params, api) {
							let x0 = api.getWidth() / 2;
							let y0 = api.getHeight() / 2;
							let r = Math.min(api.getWidth(), api.getHeight()) / 2 * 0.3;
							let point = getCirlPoint(x0, y0, r, (180 + angle))
							return {
								type: 'circle',
								shape: {
									cx: point.x,
									cy: point.y,
									r: 3
								},
								style: {
									stroke: "#4386FA", //绿
									fill: "#4386FA"
								},
								silent: true
							};
						},
						data: [0]
					},

					{
						name: "ring5",
						type: 'custom',
						coordinateSystem: "none",
						renderItem: function(params, api) {
							return {
								type: 'arc',
								shape: {
									cx: api.getWidth() / 2,
									cy: api.getHeight() / 2,
									r: Math.min(api.getWidth(), api.getHeight()) / 2 * 0.35,
									startAngle: (270 + -angle) * Math.PI / 180,
									endAngle: (40 + -angle) * Math.PI / 180
								},
								style: {
									stroke: "#0CD3DB",
									fill: "transparent",
									lineWidth: 1.5
								},
								silent: true
							};
						},
						data: [0]
					},
					// 橘色

					{
						name: "ring5",
						type: 'custom',
						coordinateSystem: "none",
						renderItem: function(params, api) {
							return {
								type: 'arc',
								shape: {
									cx: api.getWidth() / 2,
									cy: api.getHeight() / 2,
									r: Math.min(api.getWidth(), api.getHeight()) / 2 * 0.35,
									startAngle: (90 + -angle) * Math.PI / 180,
									endAngle: (220 + -angle) * Math.PI / 180
								},
								style: {
									stroke: "#FF8E89",
									fill: "transparent",
									lineWidth: 1.5
								},
								silent: true
							};
						},
						data: [0]
					}, {
						name: "ring5",
						type: 'custom',
						coordinateSystem: "none",
						renderItem: function(params, api) {
							let x0 = api.getWidth() / 2;
							let y0 = api.getHeight() / 2;
							let r = Math.min(api.getWidth(), api.getHeight()) / 2 * 0.35;
							let point = getCirlPoint(x0, y0, r, (90 + -angle))
							return {
								type: 'circle',
								shape: {
									cx: point.x,
									cy: point.y,
									r: 3
								},
								style: {
									stroke: "#FF8E89", //粉
									fill: "#FF8E89"
								},
								silent: true
							};
						},
						data: [0]
					}, {
						name: "ring5", //绿点
						type: 'custom',
						coordinateSystem: "none",
						renderItem: function(params, api) {
							let x0 = api.getWidth() / 2;
							let y0 = api.getHeight() / 2;
							let r = Math.min(api.getWidth(), api.getHeight()) / 2 * 0.35;
							let point = getCirlPoint(x0, y0, r, (270 + -angle))
							return {
								type: 'circle',
								shape: {
									cx: point.x,
									cy: point.y,
									r: 3
								},
								style: {
									stroke: "#0CD3DB", //绿
									fill: "#0CD3DB"
								},
								silent: true
							};
						},
						data: [0]
					},]
				};
				this.leftEchartsA.setOption(option);
			},
			//设置风险等级
			async riskLevelInit(){
				this.leftEchartsA = this.leftEchartsA?this.leftEchartsA:echarts.init(document.getElementById('left-box-a'));
                const res=await this.$http.get(`/api/ele/web/bigScreen/jg/fxdj`)
                if(res.status==200&&res.data.code==200){
                    this.riskData=res.data.data
                }
                this.drawRiskLevel()
				if(this.timerId){
					clearInterval(this.timerId)
				}
				this.timerId = setInterval(()=> {
					this.drawRiskLevel()
				}, 100);
			},
			//打开风险统计
			openRisk(){
				this.riskVisible=true
			},

			
			//设置维修情况
			async getMaintenanceAndRepair(date,dateVal=this.repairDateVal){
                this.repairDate=date?date:this.repairDate?this.repairDate:this.getMonth()
				this.repairDateVal=dateVal
                const res=await this.$http.post(`/api/ele/web/elevatorInfo/getMaintenanceAndRepair`,this.repairDate)
                if(res.status==200&&res.data.code==200){
					let data=res.data.data
					for (let i in data){
						if(!data[i]){
							data[i]=0
						}
					}
                    this.repairData=data
                }
			},

            //获取底部数据
            async getMaintainEleDown(){
				const {start,end}=this.getThirty()
				this.startMaintainEleDown=start
				this.endMaintainEleDown=end
                const res=await this.$http.get(`/api/ele/web/elevatorInfo/getMaintainEleDown?start=${start}&end=${end}`)
                if(res.status==200&&res.data.code==200){
					let data=res.data.data
					for (let i in data){
						if(!data[i]){
							data[i]=0
						}
					}
                    const {eleCount,failureTaskCount,maintainTaskNum,onlineCount,unOnlineCount,zeroFailureTime}=data
					this.footerRes=data
                    this.footerData[0].value=eleCount
                    this.footerData[1].value=zeroFailureTime
                    this.footerData[2].value=onlineCount
                    this.footerData[3].value=unOnlineCount
                    this.footerData[4].value=failureTaskCount
                    this.footerData[5].value=maintainTaskNum
                }
            },

            //获取维保成绩
            async getMaintenancePerformance(date){
                this.maintenanceDate=date?date:this.maintenanceDate?this.maintenanceDate:this.getMonth()
                const res=await this.$http.post(`/api/ele/web/elevatorInfo/getMaintenancePerformance`,this.maintenanceDate)
                if(res.status==200&&res.data.code==200){
                    this.maintenanceData=res.data.data
                }
            },

            //获取事件中心数据
            async getFnList(){
                const ssjyData=await this.$http.get(`/api/ele/web/bigScreen/jg/ssjy`)
                const ssgzData=await this.$http.get(`/api/ele/web/bigScreen/jg/ssgz`)
                if(ssjyData.status==200&&ssjyData.data.code==200&&ssgzData.status==200&&ssgzData.data.code==200){
                    let ssjyArr=ssjyData.data.data.map(item=>({
                        ...item,
                        fnStatus:1,
                        rescueStatus:item.status,
                        rescueTitle:item.eleName,
                        rescueDate:item.createTime,
                        rescueTime:secondToHMS(((item.status==4?new Date(item.finishTime).getTime():new Date().getTime())-new Date(item.createTime).getTime())/1000,':',true)
                    }))
                    let ssgzArr=ssgzData.data.data.map(item=>({
                        ...item,
                        fnStatus:2,
                        faultStatus:item.sosType,
                        faultTitle:item.eleName,
                        faultDate:item.sosTime,
                    })) 
                    ssgzArr=ssgzArr.filter(item=>[4,5,7,8,9,10,11,12,13,95].indexOf(item.sosType)!=-1)
                    this.fnList=ssjyArr.concat(ssgzArr)
                    this.fnList.sort((a,b)=>{
                        return new Date(a.createTime).getTime()-new Date(b.sosTime).getTime()<0
                    })
                }
            },
			async allEventStatisticsInit(time,dateVal){
				this.eventStatisticsInit(time,dateVal)
				this.getAverageOperation(time,dateVal)
			},
            //设置事件统计
			async eventStatisticsInit(date,dateVal=this.eventDateVal){
				this.rightEchartsBShow=false
                this.eventDate=date?date:this.eventDate?this.eventDate:this.getMonth()
				this.eventDateVal=dateVal
                const {start,end}=this.eventDate
				this.rightEchartsB = this.rightEchartsB?this.rightEchartsB:echarts.init(document.getElementById('right-box-b'));
                const res=await this.$http.get(`/api/ele/web/bigScreen/jg/sjtj?start=${formatDate(start)}&end=${formatDate(end)}`)
                if(res.status==200&&res.data.code==200){
                    this.eventData=res.data.data
                }
				let data=!this.rightEchartsBHasClick?[1,1,1]:[this.eventData.gaojinshu,this.eventData.guzhangshu,this.eventData.kunren]
				const colors=[
					[
						{
							offset: 0,
							color: 'rgba(0,103,184,1)'
						},
						{
							offset: 0.5,
							color: 'rgba(0,67,112,1)'
						},
						{
							offset: 0.5,
							color: 'rgba(0,103,184,1)'
						},
						{
							offset: 1,
							color: 'rgba(0,67,112,1)'
						}
					],
					[
						{
							offset: 0,
							color: '#66DFDF'
						},
						{
							offset: 0.5,
							color: '#419DA3'
						},
						{
							offset: 0.5,
							color: '#66DFDF'
						},
						{
							offset: 1,
							color: '#419DA3'
						}
					],
					[
						{
							offset: 0,
							color: '#FFB4B4'
						},
						{
							offset: 0.5,
							color: '#FF6060'
						},
						{
							offset: 0.5,
							color: '#C69199'
						},
						{
							offset: 1,
							color: '#FF7777'
						}
					],
				]

				const option = {
					// backgroundColor: "#111c4e",
					grid: {
						left: '3%',
						right: '3%',
						bottom: '10%',
						top: '30%',
						containLabel: true
					},
					yAxis: [{
						type: 'value',
						axisLabel: {
							show: false,

						},
						splitLine: {
							show: false
						},
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						}
					}],
					xAxis: [{
						type: 'category',
						axisTick: {
							show: false
						},
						data: ['告警数', '故障数', '困人数'],
						axisLabel: {
							margin: 30,
							color: '#fff',
							fontSize: 16
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: false
						},
						axisLine: {
							show: false
						},
					}],
					series: [
						{
							type: 'pictorialBar',
							symbol: 'path://M35,0L35,70L0,70z M35,0L35,70L70,70z',
							data,

							barWidth: '80%',
							// barGap: 1, //柱子之间间距
							symbolOffset: [0, -12],

							z: 99,

							label: {
								show: true,
								position: 'top',
								color: '#fff',
								fontSize: 14,
								formatter: function(params) {
									return parseFloat(params.value).toLocaleString();
								},
                 				offset:[0,-12]
							},
							itemStyle: {
								normal: {
									color: function(params) {
										return new echarts.graphic.LinearGradient(0, 0, 1, 0, colors[params.dataIndex])
									},
									opacity: 1,
								}
							}
						},
						{
							name: '',
							type: 'pictorialBar',
							symbol: 'diamond',
							barWidth: '80%',
							symbolSize: ['100%', 30],
							z: 99,
							itemStyle: {
								normal: {
									color: function(params) {
										return new echarts.graphic.LinearGradient(0, 0, 1, 0, colors[params.dataIndex])
									},
									opacity: 1,
								}
							},
							data,
						}
					]
				};
				this.rightEchartsB.setOption(option);
				if(!this.rightEchartsBHasClick){
					this.rightEchartsBHasClick=true
					this.rightEchartsB.on('click', (param)=> { 
						this.$refs.rightBoxB.removeTimer()
						let dateText=this.eventDateVal==1?'本周':this.eventDateVal==3?'本年':'本月'
						if(param.name=='告警数'){
							this.warningTitle=`${dateText}告警记录`
							this.warningVisible=true
							this.warningType=1
						}else if(param.name=='故障数'){
							this.faultTitle=`${dateText}故障记录`
							this.faultVisible=true
							this.faultType=2
						}else if(param.name=='困人数'){
							this.rescueTitle=`${dateText}困人记录`
							this.rescueVisible=true
						}
					})
					this.eventStatisticsInit(date)
				}
				setTimeout(() => {
					this.rightEchartsBShow=true
				}, 500);
			},


            //获取当月开始结束时间
            getMonth(){
                return {
                    start:(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(0, 0, 0))).getTime(),
                    end:(new Date(new Date(new Date().getFullYear(), new Date().getMonth()+ 1, 0).setHours(23, 59, 59, 59))).getTime()
                }
            },
			//获取往前三十天开始结束时间
            getThirty(){
                return {
                    start:new Date(new Date(new Date().getTime()-86400000*30).setHours(0, 0, 0)).getTime(),
                    end:new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 59)).getTime()
                }
            },
            //时间定时器
            timeInit(){
                const date=formatDate(new Date())
                this.timeVal=date.split(' ')
                this.timer=setInterval(()=>{
                    const date=formatDate(new Date())
                    this.timeVal=date.split(' ')
					this.day=getDay()
                },1000)
            },
            //全屏方法
            handleFullScreen() {
                 // 此处可根据获取节点进行区域全屏事件
                let element = document.documentElement;
                if (this.fullscreen) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                } else {
                    if (element.requestFullscreen) {
                        element.requestFullscreen();
                    } else if (element.webkitRequestFullScreen) {
                        element.webkitRequestFullScreen();
                    } else if (element.mozRequestFullScreen) {
                        element.mozRequestFullScreen();
                    } else if (element.msRequestFullscreen) {
                        // IE11
                        element.msRequestFullscreen();
                    }
                }
                this.fullscreen = !this.fullscreen;
            },
			//监听屏幕是否全屏
			fullScreenChangeFn(e){
				if(window.screen.height - window.document.body.offsetHeight > 5){
					this.fullscreen = false;
				}else{
					this.fullscreen = true;
				}
			},
			//监听按键
			fullScreenKeydown(e){
				e = e || window.event
				if (e.keyCode===122 && !this.fullscreen) {
					e.preventDefault()
					this.handleFullScreen()
				}
			},
			//秒转天时
			getTimeVal(s){
				return getTimeVal(s)
			},
			//获取平均运行统计
            async getAverageOperation(date){
				this.eventDate=date?date:this.eventDate?this.eventDate:this.getMonth()
                const {start,end}=this.eventDate
				const res=await this.$http.get(`/api/ele/web/bigScreen/jg/dbsjl?start=${formatDate(start)}&end=${formatDate(end)}`)
                if(res.status==200&&res.data.code==200){
                    this.averageOperation=res.data.data
                }
            },
            pageInit(resetMap=false){
                const index=this.echartsHistory.length>0?this.echartsHistory.length-1:0
                const mapData=this.echartsHistory[index]
                const regionData=mapData?mapData.regionData:undefined
                Promise.all([this.echartMapInit(regionData,resetMap),this.riskLevelInit(),this.getMaintenancePerformance(),this.getMaintenanceAndRepair(),
                this.getFnList(),this.eventStatisticsInit(),this.getMaintainEleDown(),this.getAverageOperation()])
                .then(res=>{
                    this.isLodin = false
                })
            },
			//重置地图
			mapRest(){
				if(this.showMapGD){
					mapGD.setZoomAndCenter(13,this.mapCenterGD)
				}else{
					this.echartMapInit(undefined,true)
				}
			},
			//地图返回
			mapBack(){
				if(this.showMapGD){
					this.showMapGD=false
				}else{
					let historyLength=this.echartsHistory.length
					if(historyLength>1){
						this.goRegion(this.echartsHistory[historyLength-2])
					}
				}
			},
			mapZoomAdd(){
				if(this.showMapGD){
					let zoom=mapGD.getZoom()+0.2
					mapGD.setZoom(zoom)
				}else{
					let option = myChart.getOption(); 
					option.geo[0].zoom+=0.2
					myChart.setOption(option,{lazyUpdate:true}); 
				}
			},
			mapZoomReduce(){
				if(this.showMapGD){
					let zoom=mapGD.getZoom()-0.2
					mapGD.setZoom(zoom)
				}else{
					let option = myChart.getOption(); 
					option.geo[0].zoom-=0.2
					myChart.setOption(option,{lazyUpdate:true}); 
				}
			},

			//打开底部电梯弹框
			openLineVisible(index){
				if(index==0||index==2||index==3||index==4){
					this.lineVisible=true
					this.initLineStatus=index==2?1:index==3?2:0
					this.initFaultStatus=index==4?2:0
				}else if(index==5){
					this.elevatorMaintenanceType=1
					this.elevatorMaintenanceVisible=true
					this.elevatorMaintenanceTitle="维保记录"
					this.elevatorMaintenanceStatus='7'
				}else if(index==1){
					this.faultType=1
					this.faultVisible=true
					this.faultTitle="30天故障记录"
				}
				
			},

			//打开维保成绩
			openMaintenanceResults(item){
				this.$refs.leftBoxB.removeTimer()
				if(item.name=='维保人员'){
					this.personnelVisible=true
				}else if(item.name=='超期维保'||item.name=='超期保养'){
					this.elevatorMaintenanceVisible=true
					this.elevatorMaintenanceTitle=item.name
					this.elevatorMaintenanceType=2
					this.elevatorMaintenanceStatus='5'
				}
			},

			//打开维保维修情况
			openMaintenanceItem(item){
				this.$refs.leftBoxC.removeTimer()
				let dateText=this.repairDateVal==1?'本周':this.repairDateVal==3?'本年':'本月'
				if(item=='维保完成率'){
					this.elevatorMaintenanceVisible=true
					this.elevatorMaintenanceType=3
					this.elevatorMaintenanceTitle=`${dateText}已维保`
					this.elevatorMaintenanceStatus='7'
				}else if(item=='待维保任务'){
					this.elevatorMaintenanceVisible=true
					this.elevatorMaintenanceType=4
					this.elevatorMaintenanceTitle=`${dateText}待维保`
					this.elevatorMaintenanceStatus='0'
				}else if(item=='维修处理中'){
					this.repairVisible=true
					this.repairTitle=`${dateText}维修处理中`
					this.repairStatus=2
				}else if(item=='待维修处理'){
					this.repairVisible=true
					this.repairTitle=`${dateText}待维修处理`
					this.repairStatus=1
				}
			},

			//打开救援详情
			openRescueDetail(item){
				this.$http
				.post("/api/ele/web/rescueTask/getInfo", {
					id: item.id
				})
				.then((res) => {
					if (res.data.success) {
						res.data.data.sosVideo.forEach((qs) => {
							let url = qs.videoUrl.split('?');
							qs.videoUrl = url[0]
							qs.videoName = url[1].split('&')[0].split('=')[1]
							// qs.videoName = url[8]
						})
						res.data.data.sosTypeName = this.getTypeText(item.sosType)
						res.data.data.sosStatusName = this.getStatusText(res.data.data.sosStatus)
						res.data.data.speed = res.data.data.speed !== '' ? parseFloat(res.data.data.speed) : 0
						res.data.data.speedarr = res.data.data.speedRecord.split(',');

						this.rescueDetailsItem.seleId = 1
						this.rescueDioDetailsItem = res.data.data
						this.rescueDetailVisible = true

					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			getStatusText(status){
				const list=this.statusList.filter(item=>item.dictKey==status)
				return list.length>0?list[0].dictValue:status
			},
			getTypeText(status){
				const list=this.typeList.filter(item=>item.dictKey==status)
				return list.length>0?list[0].dictValue:status
			},
			//打开告警详情
			openWarningDetail(item){
				this.$http.post("/api/ele/web/sos/getInfo", {
					id: item.id,
          			sosTime: item.sosTime ? item.sosTime : ''
				})
				.then((res) => {
					if (res.data.success) {
						res.data.data.sosVideo.forEach((qs) => {
							let url = qs.videoUrl.split('?');
							qs.videoUrl = url[0]
							qs.videoName = url[1].split('&')[0].split('=')[1]
						})
						res.data.data.sosTypeName = item.sosType == -1 ? item.sosText :  this.getTypeText(item.sosType)
						res.data.data.sosStatusName = this.sosStatusOptions.find(item => item.value === res.data.data
								.sosStatus) ? this.sosStatusOptions.find(item => item.value === res.data.data.sosStatus)
							.label : ''
						res.data.data.speed = res.data.data.speed !== '' ? parseFloat(res.data.data.speed) : 0
						res.data.data.speedarr = res.data.data.speedRecord.split(',');

						this.warningDetailsItem.seleId = 1
						this.warningDioDetailsItem = res.data.data
						this.warningDetailVisible = true

					} else {
						this.$message.error(res.data.msg)
					}
				})
			},

			mouseSeamlessItem(e,isMouse){
				const item = e.target.closest(".seamless-row");
				if(item){
					if(isMouse){
						item.classList.add('active-item')
					}else{
						item.classList.remove("active-item");
					}
				}
			},
			openseamlessItem(e){
				const item = e.target.closest(".seamless-row");
				if(item){
					let obj =JSON.parse(item.dataset.obj)
					let type=item.dataset.type
					if(type==1){
						this.openRescueDetail(obj)
					}else{
						this.openWarningDetail(obj)
					}
				}
			},

			personnelClose({openType}){
				this.personnelVisible=false
				this.$refs.leftBoxB.initTimer()
			},
			elevatorMaintenanceClose({openType}){
				this.elevatorMaintenanceVisible=false
				if(openType==2){
					this.$refs.leftBoxB.initTimer()
				}else if(openType==3||openType==4){
					this.$refs.leftBoxC.initTimer()
				}
			},
			repairClose({openType}){
				this.repairVisible=false
				this.$refs.leftBoxC.initTimer()
			},
			warningClose({openType}){
				this.warningVisible=false
				this.$refs.rightBoxB.initTimer()
			},
			faultClose({openType}){
				this.faultVisible=false
				if(openType==2){
					this.$refs.rightBoxB.initTimer()
				}
			},
			rescueColse({openType}){
				this.rescueDetailVisible=false
				this.$refs.rightBoxB.initTimer()
			},
			toManageAreaCode(arr){
				arr=arr?JSON.parse(arr):arr
				let manageAreaCode=(!arr||!arr[0])?[]:arr[0]
				return ['',...manageAreaCode]
			},
			checkBigScreenType(){
				const screenData = JSON.parse(sessionStorage.getItem('adminInfo'))
				let bigScreenType = screenData.bigScreenType
				if(bigScreenType!=3){
					this.$router.push('/dashboard')
				}
			}

		},
		mounted() {
			this.checkBigScreenType()
			this.$nextTick(() => {
				openLz();
			});
			myChart = echarts.init(document.getElementById('myChart'));

			const regionData={name:'中国',adcode:0,level:''}
			const adminInfo=JSON.parse(sessionStorage.getItem('adminInfo'))
			if(adminInfo.manageAreaCode){
				let arr=this.toManageAreaCode(adminInfo.manageAreaCode)
				let val=arr.length>3?2:arr.length-1
				regionData.level=arr.length==1?'':arr.length==2?'province':arr.length==3?'city':'district'
				val=arr[val]
				if(val!=''){
					val=val.toString()
					regionData.adcode=val.substring(0,6)
					regionData.name=adminInfo.areaNames
				}
			}
			this.initRegionData=regionData

			this.pageInit(true)
            this.pageTimer=setInterval(()=>{
                this.pageInit()
            },60000)

            //绑定点击事件
			this.bindClick()
            //时间计算
            this.timeInit()

			if(window.screen.height - window.document.body.offsetHeight > 5){
				this.fullscreen = false;
			}else{
				this.fullscreen = true;
			}
			// 阻止F11键默认事件，用HTML5全屏API代替
			window.addEventListener('keydown',this.fullScreenKeydown)
			window.addEventListener('resize',this.fullScreenChangeFn)
		},
		beforeDestroy(){
			clearInterval(this.facilityTimer)
            clearInterval(this.timer)
            clearInterval(this.pageTimer)
			clearInterval(this.timerId)
			clearTimeout(this.timeOutEr)

			window.removeEventListener('resize',this.fullScreenKeydown)
			window.removeEventListener('resize',this.fullScreenChangeFn)
		}
	}
</script>

<style>
	@font-face {
		font-family: 'led regular';
		src: url('../../font/Digital-7.ttf');
	}

	.amap-info-content {
		padding: 0px !important;
	}
</style>

<style lang="scss" scoped>
	.ellipsis{
		overflow: hidden; 
		white-space: nowrap; 
		text-overflow: ellipsis; 
	}
	.lodinBack {
		width: 100%;
		height: 100vh;
		position: absolute;
		z-index: 99999;
	}
	.tbdod {
		width: 100%;
		height: 100vh;
		background: url(../../assets/lodinbg.png) no-repeat;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
	.topBgBoxCent {
		// width: 26%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.topBgBox1 {
        display: flex;
        align-items: center;
		width: 35%;
		height: 100%;
		// background: url(../../assets/topTitright.gif) no-repeat;
		// background-position: center left;
		// background-size: 100% 100%;
	}
	.top-left-img{
		position: absolute;
		top: 50%;
		width: 100%;
		transform: translateY(-50%);
	}
    .top-right-img{
		flex:1;
		max-width: calc(100% - 170px);
    }

	.topBgBox2 {
		position: relative;
		width: 35%;
		height: 100%;
		// background: url(../../assets/topTitrleft.gif) no-repeat;
		// background-position: center right;
		// background-size: 100% 100%;
	}

	.topBg {
		height: 100px;
		position: absolute;
		z-index: 998;
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
	}
	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.title {
			font-size: 1.5vw;
			color: white;
			padding: 0 10px;
		}
	}
    .top-right{
        // min-width: 214px;
        display: flex;
        align-items: center;
		justify-content: space-between;
		position: absolute;
		right: 10px;
    }
	.day{
		color: #fff;
		font-size: 20px;
		margin-left: 10px;
	}
    .top-time{
        color: #fff;
        text-align: right;
    }
    .top-time-date{
        font-size: 21px;
    }
	.titBut {
		margin-right: 10px;
	}
	.back-btn{
        width: 144px;
        height: 35px;
        line-height: 35px;
        font-size: 20px;
        text-align: center;
        color: #fff;
        background: url(../../assets/newBigmianban/btn-bg.png) no-repeat;
		background-position: center;
		background-size: contain;
        cursor: pointer;
    }

	.map-container /deep/ .el-breadcrumb__inner.is-link{
		color: #fff;
	}
	.map-container{
		position: fixed;
		left:50%;
		top: 50%;
		transform: translate(-50%,-50%);
		// width: 38%;
		width: calc(100% - 1082px);
		height: 78vh;
	}
	.echarts-container,#myChart,.mapGD-container,#mapGD{
		width: 100%;
		height: 100%;
	}
	.echarts-history{
		position: absolute;
		top: 20px;
		left: 20px;
	}
	#mapGD {
		opacity: 0.92;
	}
	.back-echarts{
		position: absolute;
		top: 20px;
		left: 20px;
	}
    .title-container{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
	.left-box {
		position: absolute;
		width: 521px;
		top: 11vh;
		left: 10px;
		height: 86vh;
	}
	.right-box{
		position: absolute;
		width: 521px;
		top: 11vh;
		right: 10px;
		height: 86vh;
	}

	.left-box-a,.left-box-b,.left-box-c,.right-box-a,.right-box-b,.right-box-c {
		position: absolute;
		width: 92%;
		height: 30%;
		padding: 1% 4% 4% 4%;
		background: url(../../assets/tlbg.png) no-repeat;
		background-size: 100% 100%;
		background-color: rgba(255,255,255,0.1);
	}
    .left-box-a,.right-box-a{
		top: 0;
	}
	#left-box-a{
		height: 100%;
    	margin-top: 0%;
	}
	.left-box-b{
        height: 22%;
		top: 34%;
	}
    .left-box-c{
        height: 38%;
        top: 60%;
    }
    .right-box-a,.right-box-b{
        height: 47%;
    }
    .right-box-b{
        top: 51%;
    }
	#right-box-b{
		height: 200px;
		background: url(../../assets/newBigmianban/base.png) no-repeat;
		background-size: 408px 31px;
		background-position: center 71%;
	}
	.right-box-c{
		top: 68%;
	}
	.average-operation-content{
		display: flex;
		align-items: center;
		margin-left: 97px;
	}
	.average-operation-line{
		width: 2px;
		height: 15px;
		border-radius: 1px;
		margin-right: 9px;
	}
	.average-operation-text{
		margin-right: 24px;
		font-size: 16px;
	}
	.average-operation-num-conainer{
		width: 80px;
		text-align: right;
	}
	.average-operation-num{
		// font-family: 'led regular';
		font-size: 36px;
		margin-right: 4px;
	}
	.average-operation-container{
		// margin-top: 10px;
		position: absolute;
		left: 65px;
		bottom: 30px;
	}
	.average-operation-content-1{
		color: #07A6FF;
		.average-operation-line{
			background-color: #07A6FF;
		}
		.average-operation-line{
			color: #07A6FF;
		}
	}
	.average-operation-content-2{
		margin-top: 20px;
		color: #FFFE88;
		.average-operation-line{
			background-color: #FFFE88;
		}
		.average-operation-line,.average-operation-line{
			color: #FFFE88;
		}
	}
    .maintenance-list,.repair-list{
        display: flex;
        flex-wrap: wrap;
        width: 280px;
        justify-content: space-between;
        margin: 0 auto;
    }
    .maintenance-item{
        display: flex;
        align-items: center;
        margin-top: 14px;
		cursor: pointer;
    }
    .maintenance-img{
        width: 40px;
        height: 40px;
        margin-right: 4px;
    }
    .maintenance-text{
        font-size: 16px;
        color: #fff;
		width: 70px;
    }
    .repair-item{
        position: relative;
        width: 110px;
        height: 110px;
        margin-top: 6px;
        background: url(../../assets/newBigmianban/meter.gif) no-repeat;
		background-position: center center;
		background-size: contain;
		cursor: pointer;
    }
    .repair-content{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: #fff;
        font-size: 20px;
        text-align: center;
		// font-family: 'led regular';
    }
	.repair-unit{
		font-size: 15px;
		margin-left: 2px;
	}
    .repair-title{
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: 70px;
        height: 22px;
        line-height: 22px;
        background: #07A6FF;
        color: #fff;
        font-size: 12px;
        text-align: center;
        border-radius: 6px;
    }
	.box-img {
		width: 376px;
		height: 40px;
		display: flex;
		align-items: center;
		background: url(../../assets/msgTit.png) no-repeat;
		background-position: bottom center;
		background-size: contain;
	}

	.box-img span {
		color: white;
		font-size: 18px;
		margin-left: 10px;
	}
	.chart{
		width: 100%;
		height: 80%;
		margin-top: 4%;
		overflow: hidden;
	}

	
	.right-a-list-container,.right-b-list-container{
		height: 100%;
		overflow: hidden;
	}
	.right-a-list,.right-b-list {
		display: flex;
		flex-wrap: nowrap;
		flex-direction: column;
	}
	.right-a-item,.right-b-item{
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #fff;
		cursor: pointer;
	}
	.right-a-item{
		margin-bottom: 3%;
		padding: 1%;
	}
	.active-item{
		background: rgba(1,230,231,0.17);
		
	}
	.sos{
		min-width: 80px;
		height: 80px;
		line-height: 80px;
		font-size: 23px;
		text-align: center;
		background: url(../../assets/newBigmianban/sos.gif) no-repeat;
		background-position: bottom center;
		background-size: contain;
	}
	.right-a-title-container,.right-b-title-container{
		flex: 1;
		margin:0 18px;
		color: #fff;
	}
	.right-a-title{
		max-width: 220px;
		font-size: 20px;
	}
	.right-b-title{
		max-width: 362px;
	}
	.right-a-time-container{
		display: flex;
		align-items: center;
		margin-right: 10px;
	}
	.right-a-img{
		margin-right: 4px;
	}
	.right-a-date,.right-a-time,.right-a-status,.right-b-date{
		font-size: 12px;
	}
	.right-a-status{
		width: 53px;
		height: 28px;
		line-height: 28px;
		border-radius: 4px;
		text-align: center;
	}
	.rescue-status-1{
		.right-a-title-container{
			color: #FF7070;
		}
		.right-a-time{
			color: #FF0000;
		}
		.right-a-status{
			background-color: #FF0000;
		}
	}
	.rescue-status-2{
		.right-a-title-container{
			color: #FF7070;
		}
		.right-a-time{
			color: #FF0000;
		}
		.right-a-status{
			background-color: #FF7070;
		}
	}
	.rescue-status-3{
		.right-a-title-container{
			color: #fff;
		}
		.right-a-time{
			color: #02AF90;
		}
		.right-a-status{
			background-color: #02AF90;
		}
	}
	.right-b-img{
		width: 27px;
		height: 27px;
		margin: 0 9px;
	}
	.right-b-status{
		// width: 53px;
		padding: 0 6px;
		height: 28px;
		line-height: 28px;
		text-align: center;
		border-radius: 4px;
		font-size: 12px;
		color: #fff;
	}
	.right-b-title{
		font-size: 18px;
	}
	.fault-status-4{
		.right-b-status{
			background-color: #03a18d;
		}
	}
	.fault-status-5{
		.right-b-title-container{
			color: #ff0000;
		}
		.right-b-status{
			background-color: #ff0000;
		}
	}
	.fault-status-7{
		.right-b-status{
			background-color: #ffa0a0;
		}
	}
	.fault-status-8{
		.right-b-status{
			background-color: #00cf3a;
		}
	}
	.fault-status-9{
		.right-b-status{
			background-color: #0090ff;
		}
	}
	.fault-status-10{
		.right-b-status{
			background-color: #046ebf;
		}
	}
	.fault-status-11{
		.right-b-status{
			background-color: #ff6000;
		}
	}
	.fault-status-12{
		.right-b-status{
			background-color: #ff32f8;
		}
	}
	.fault-status-13{
		.right-b-status{
			background-color: #ff6262;
		}
	}
	.fault-status-95{
		.right-b-status{
			background-color: #2ecbff;
		}
	}
	.right-b-item{
		margin-bottom: 4%;
		padding: 2% 0;
	}
	.footer{
        position: absolute;
        left: 50%;
        bottom: 2%;
        display: flex;
        justify-content: space-between;
        width: calc(100% - 1082px);
		max-width: 841px;
        transform: translateX(-50%);
        box-sizing: border-box;
        padding: 28px 4px;
        background: rgba(28,28,29,0.75);
    }
    .footer-item{
        display: flex;
        flex: 1;
		max-width: 113px;
        flex-direction: column;
        text-align: center;
        color: #fff;
        margin: 0 4px;
		cursor: pointer;
    }
    .footer-num{
        font-size: 24px;
		// font-family: 'led regular';
		margin-right: 2px;
    }
    .footer-num-container{
        // max-width: 100px;
        margin: 0px auto;
    }
    .footer-img-container{
        width: 100%;
    }
    .footer-img{
        width: 100%;
    }
    .footer-text{
        margin-top: 8px;
    }
    .full-Screen{
        width: 35px;
        height: 35px;
        background: url(../../assets/newBigmianban/screen-2.png) no-repeat;
		background-position: center;
		background-size: 100% 100%;
        cursor: pointer;
        margin: 0 11px;
    }
    .close-full-Screen{
        background: url(../../assets/newBigmianban/screen-1.png) no-repeat;
        background-position: center;
		background-size: 100% 100%;
    }
	.map-icon-box{
		position: absolute;
		right: 10px;
		bottom: 130px;
		display: flex;
		flex-direction: column;
	}
	.map-icon{
		width: 32px;
		height: 32px;
		margin-top: 4px;
		cursor: pointer;
	}
	.null-list{
		margin-top: 60px;
		text-align: center;
		color: #fff;
	}

</style>


<style lang="scss">
	@import './css/index.scss';
</style>
